import React, { Fragment } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import PrintFabricGrid from '../components/PrintFabricGrid';
import { Helmet } from 'react-helmet-async';

const Home: React.FC = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Bharat Expressions - Fusion Indian Ethnic Wear</title>
        <meta name="description" content="Discover our fusion Indian ethnic wear, blending traditional prints with contemporary styles." />
      </Helmet>
      <section className="bg-brand-purple text-white py-20 border-pattern border-t-8 border-b-8">
        <div className="flex flex-col md:flex-row container mx-auto px-4 text-center md:text-left">
            <div className="flex-none w-64 md:w-80 sm:w-50 mx-auto mb-4 md:mb-0 bg-white rounded-xl shadow-md">
                <LazyLoadImage
                  src="/media-opt/assets/logos/BE-Logo-v1.png" // Make sure this image exists
                  alt="Bharat Expressions Showcase"
                  effect="blur"
                  className="w-full h-full object-cover"
                />
            </div>
            <div className="flex-auto p-6">
                <h1 className="text-5xl md:text-7xl font-sacramento mb-4">Celebrating Heritage</h1>
                <h2 className="text-3xl md:text-5xl font-bold mb-8">Through Modern Design</h2>
                <p className="text-xl mb-8">Discover our fusion Indian ethnic wear, blending traditional prints with contemporary styles.</p>
                <a href="https://www.amazon.in/Bharat-Expressions-Womens-Ethnic-Fusion/dp/B0DF2HZVK9/" target='_blank'
                    className="bg-brand-orange text-brand-purple py-3 px-8 rounded-full text-lg font-semibold hover:bg-brand-red hover:text-white transition">Shop Now on Amazon</a>            
            </div>
        </div>
      </section>
      <section className="bg-brand-orange py-16 border-pattern border-t-8 border-b-8">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-sacramento text-brand-purple text-center mb-8">
            Our USP - Unique Prints and Fabrics
          </h2>
          <p className="text-center mb-8">
            Sourced from the hamlets of Kutch, our designs bring the timeless beauty of Ajarakh, Kalamkari, Bandhej, Patola, Batik, and Dabu prints to life on fabrics like Mashru Silk, Cotton, Modal, Rayon, and Kutch Cotton.
          </p>
          
          <h3 className="text-2xl font-sacramento text-brand-purple mb-4">Prints</h3>
          <PrintFabricGrid type="print"/>
          
          <h3 className="text-2xl font-sacramento text-brand-purple mb-4">Fabrics</h3>
          <PrintFabricGrid type="fabric"/>
        </div>
      </section>
    </Fragment>
  );
};

export default Home;