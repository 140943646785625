import React from "react";
import ProductGrid from "../components/ProductGrid";
import { Helmet } from "react-helmet-async";
import { ShoppingBag } from "lucide-react";

const Products: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>
          Our Products - Bharat Expressions | Unique Prints and Fabrics
        </title>
        <meta
          name="description"
          content="Explore our collection of fusion Indian ethnic wear featuring Ajarakh, Kalamkari, Bandhej prints on Mashru Silk, Cotton, and more."
        />
      </Helmet>

      <div className="container mx-auto px-4 py-8">
        {/* Responsive Amazon Callout Section */}
        <div className="mb-12 overflow-hidden rounded-lg shadow-lg">
          <div className="bg-gradient-to-r from-brand-orange to-brand-red text-white p-8 sm:p-12 relative">
            <div className="absolute top-0 right-0 w-24 h-24 sm:w-40 sm:h-40 -mr-6 -mt-6 sm:-mr-10 sm:-mt-10 bg-brand-purple opacity-10 rounded-full"></div>
            <div className="absolute bottom-0 left-0 w-24 h-24 sm:w-40 sm:h-40 -ml-6 -mb-6 sm:-ml-10 sm:-mb-10 bg-brand-purple opacity-10 rounded-full"></div>
            <div className="relative z-10 flex flex-col items-center text-center">
              <h2 className="text-4xl sm:text-5xl font-bold mb-4 sm:mb-6 font-sacramento">Discover Our Collections on Amazon!</h2>
              <p className="text-lg sm:text-xl mb-6 sm:mb-8 max-w-2xl">
                Embrace the fusion of traditional Indian prints and modern styles. Our latest collection awaits you on Amazon - where heritage meets contemporary fashion.
              </p>
              <a
                href="https://www.amazon.in/Bharat-Expressions-Womens-Ethnic-Fusion/dp/B0DF2HZVK9/"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-white text-brand-purple hover:bg-brand-purple hover:text-white transition-colors duration-300 py-3 px-8 sm:py-4 sm:px-10 rounded-full text-xl sm:text-2xl font-semibold inline-flex items-center group"
              >
                <ShoppingBag className="mr-3 w-6 h-6 sm:w-8 sm:h-8 group-hover:animate-bounce" />
                Shop on Amazon
              </a>
            </div>
          </div>
        </div>

        <ProductGrid />

        <section className="bg-brand-orange p-8 rounded-lg shadow-md border-pattern border-t-8 border-b-8 mt-12">
          <h2 className="text-3xl font-bold text-brand-purple mb-6">
            Product Categories
          </h2>
          <ul className="list-disc pl-5 space-y-2 text-lg text-brand-purple">
            <li>Dresses</li>
            <li>One-pieces</li>
            <li>Shirts</li>
            <li>Tops</li>
            <li>Kurtis</li>
          </ul>
        </section>
      </div>
    </>
  );
};

export default Products;